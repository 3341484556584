// export const APP_ID = "0b47427ee7334417a90ff22c4e537b08";
// export const APP_SECRET = "d35960a9bfb146ceb33a3a40c0b9ab3b";

export const APP_ID = "76c76eecc0f44cff943b58ac64e2f372";
export const APP_SECRET = "6568e86b523343dcb64797bb8469b58a";

export const PUSHER_APP_ID = "1131489";
export const PUSHER_APP_KEY = "e700f994f98dbb41ea9f";
export const PUSHER_APP_SECRET = "7f680604ca5e7eb8cb75";

export const PUSHER_HOST = '';
export const PUSHER_PORT = 443;
export const PUSHER_SCHEME = "https";
export const PUSHER_APP_CLUSTER = "eu";